import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import OppourtunitiesBody from "../Oppourtunities/OppourtunitiesBody"
import Loader from "../Common/Loader";
import Scroll from "../Common/Scroll";
const Oppourtunities =()=>{
    useEffect(() => {
    
        setTimeout(() => {
          $(".preloader").css("display", "none");
        }, 500);
      }, []);
    return(
        <>
        <Loader/>
        <BreadcrumbHeader/>
        <OppourtunitiesBody/>
        <Footer/>
        <Scroll/>
        </>
    )
}
export default Oppourtunities