import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import WithoutFooter from "../Common/WithoutFooter";
import OuruniverseBody from "./OurUniverseBody"
import Loader from "../Common/Loader";
import Scroll from "../Common/Scroll";

const Ouruniverse=()=>{
    useEffect(() => {
    
        setTimeout(() => {
          $(".preloader").css("display", "none");
        }, 500);
      }, []);
    return(
<>
<Loader/>
<BreadcrumbHeader/>
<OuruniverseBody/>
<WithoutFooter/>
<Scroll/>


</>
    )
}
export default Ouruniverse