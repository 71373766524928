import React, { useEffect } from "react";
import BreadcrumbHeader from "../Common/BreadcrumbHeader";
import Footer from "../Common/Footer";
import Loader from "../Common/Loader";
import WithoutFooter from "../Common/WithoutFooter";
import Scroll from "../Common/Scroll";
import { Link } from "react-router-dom";

const PenTalk = () => {
  useEffect(() => {
    setTimeout(() => {
      $(".preloader").css("display", "none");
    }, 500);
  }, []);

  const readMoreStyle = {
    fontSize: "1.2rem",
    color: "#343a40",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    // textDecoration: 'underline',
    cursor: "pointer",
  };
  const categoriesStyle = {
    border: "1px solid rgb(213, 213, 213)",
    padding: "15px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "none", // Explicitly remove box shadow
  };

  return (
    <>
      <Loader />
      <BreadcrumbHeader />
      <section id="penTalkContent" className="section-padding">
        <div className="container" style={{ marginTop: "20px" }}>
          <div className="row">
            <div className="col-md-8">
              <div className="section-blog-title topTextPenTalk">
                <h2>Embrace the Future: Upgrading to Oracle Cloud</h2>
                <p>Posted on January 02, 2024 | Category Oracle ERP</p>
              </div>
              <p>
                In today's fast-paced digital world, businesses are constantly
                seeking ways to stay ahead of the curve. One powerful strategy
                is upgrading to Oracle Cloud. This transformation not only
                enhances operational efficiency but also propels organizations
                toward greater innovation and agility. Let's explore why
                upgrading to Oracle Cloud is a game-changer and how it can
                benefit your business.
                <a
                  style={{ readMoreStyle }}
                  href="/PenTalkDetails"
                  // data-toggle="modal"
                  // data-target="#jobDetailsModal"
                >
                  [Read More]
                </a>
              </p>
            </div>
            <div className="col-md-4">
              <div className="sidebar" style={categoriesStyle}>
                <div className="search-box mb-4">
                  {/* <input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                  /> */}
                </div>
                <div className="categories">
                  <h4 style={{ fontWeight: "bold" }}>Categories</h4>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        href="/PenTalk"
                        style={{
                          fontWeight: "normal",
                          fontSize: "16px",
                          color: "#000",
                          textDecoration: "none",
                        }}
                      >
                        All
                      </a>
                    </li>
                    <li>
                      <a
                        href="/PenTalkDetails"
                        style={{
                          fontWeight: "normal",
                          fontSize: "16px",
                          color: "#000",
                          textDecoration: "none",
                        }}
                      >
                        Oracle ERP
                      </a>
                    </li>
                    <li>
                      <a
                        href="/PENTalkDetailsCategoryTwo"
                        style={{
                          fontWeight: "normal",
                          fontSize: "16px",
                          color: "#000",
                          textDecoration: "none",
                        }}
                      >
                        SAP ERP
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="penTalkContent" className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-blog-title">
                <h2>
                  Driving Business Innovation with SAP S/4HANA in the Digital
                  Age
                </h2>
                <p>Posted on June 02, 2024 | Category SAP ERP</p>
              </div>
              <p className="justify">
                In today’s fast-paced digital world, businesses need agility,
                efficiency, and real-time insights to stay ahead. SAP S/4HANA,
                the next-generation ERP suite, delivers exactly that by
                leveraging the power of in-memory computing and providing a
                real-time, integrated business management platform. Here's how
                SAP S/4HANA is revolutionizing business operations across
                industries.
                <a
                  style={readMoreStyle}
                  href="/PENTalkDetailsCategoryTwo"
                  // data-toggle="modal"
                  // data-target="#jobDetailsModal"
                >
                  [Read More]
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <WithoutFooter />
      <Scroll />
    </>
  );
};

export default PenTalk;
