import Axios from "axios";
// DEV

//Axios.defaults.baseURL = "http://localhost:47189/api/";

//AZURE QA

Axios.defaults.baseURL = "https://qaapi.pensoftware.io/api/";

Axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export default class AxiosServices {
  post(url, data, Header = false) {
    return Axios.post(url, data, Header);
  }
  get(url, header = null) {
    return Axios.get(url, header);
  }
}
