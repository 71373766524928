import React, { useEffect, useState } from "react";
import RecruitmentServices from "../../../src/Services/RecruitmentServices";
var RecruitmentServicesObj = new RecruitmentServices();
import Swal from "sweetalert2";

const ApplyOppourtunities = ({ text }) => {
  console.log(text, "text");
  const [isLoaded, setIsLoaded] = useState(false);
  const [applyFormDetails, setApplyFormDetails] = useState(false);

  const onSubmit = async (data) => {
    debugger;
    data.preventDefault();
    const jobName = text;
    var data = {
      firstName: document.getElementById("firstName").value.trim(),
      lastName: document.getElementById("lastName").value.trim(),
      email: document.getElementById("email").value.trim(),
      phoneNumber: document.getElementById("contactNumber").value.trim(),
      resumePath: document.getElementById("resumePath").value.trim(),
      city: document.getElementById("city").value.trim(),
      eligibleToUS: applyFormDetails.eligibleToUS ?? -1,
      sponsershipToWorkUS: applyFormDetails.sponsershipToWorkUS ?? -1,
      jobName: jobName,
    };

    // Example of stringifying the data object
    console.log(JSON.stringify(data));

    let isValid = true;
    let isEmailValid = true;

    const setErrorState = (elementId, hasError) => {
      const element = document.getElementById(elementId);
      if (element) {
        if (hasError) {
          element.style.border = "1px solid #dd3535";
          isValid = false;
        } else {
          element.style.border = "";
        }
      } else {
        console.error(`Element with ID '${elementId}' not found`);
      }
    };

    setErrorState("firstName", data.firstName === "");
    setErrorState("lastName", data.lastName === "");
    setErrorState("email", data.email === "");
    setErrorState("contactNumber", data.phoneNumber === "");
    setErrorState("resumePath", data.resumePath === "");
    setErrorState("city", data.city === "");
    var emailcheck = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      data.email
    );
    if (emailcheck) {
      isEmailValid = false;
      Swal.fire({
        icon: "warning",
        title: "Enter valid email address",
        position: "top-end", // Positions the alert at the top-right corner
        toast: true, // Makes the alert look like a toast notification
        showConfirmButton: false, // Hides the confirm button for a cleaner look
        timer: 3000, // Automatically dismisses after 3 seconds
        timerProgressBar: true, // Shows a progress bar during the timer
        customClass: {
          container: "custom-swal-container", // Custom class for additional styling
        },
      });
    }
    var ResumePath;
    if (isValid && isEmailValid) {
      setIsLoaded(true);
      const formData = new FormData();
      $.each($("#resumePath")[0].files, function (i, file) {
        formData.append("Resume", file);
      });
      await RecruitmentServicesObj.UploadResume(formData).then(async (res) => {
        debugger;
        ResumePath = res.data;
        data.resumePath = ResumePath;
        await RecruitmentServicesObj.Create(data).then(async (responce) => {
          if (responce.data === 1) {
            setTimeout(() => {
              $("#oppourtunitiesModelClsBtn").click();
            }, 1000);

            setIsLoaded(false);

            Swal.fire({
              icon: "success",
              title: "Apply Successfully",
              position: "top-end",
              toast: true,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              customClass: {
                container: "custom-swal-container",
              },
            });
          } else if (responce.data === 0) {
            setTimeout(() => {
              $("#oppourtunitiesModelClsBtn").click();
            }, 1000);
            Swal.fire({
              icon: "error",
              title: "Something went wrong",
              position: "top-end",
              toast: true,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              customClass: {
                container: "custom-swal-container",
              },
            });
            setIsLoaded(false);
          }
        });
      });
    } else if (!isValid) {
      Swal.fire({
        icon: "error",
        title: "Please fill the mandatory fields",
        position: "top-end", // Positions the alert at the top-right corner
        toast: true, // Makes the alert look like a toast notification
        showConfirmButton: false, // Hides the confirm button for a cleaner look
        timer: 3000, // Automatically dismisses after 3 seconds
        timerProgressBar: true, // Shows a progress bar during the timer
        customClass: {
          container: "custom-swal-container", // Custom class for additional styling
        },
      });
    }
  };

  $("#oppourtunitiesModelClsBtn").click(function () {
    $("#sf")[0].reset();
    const textFields = $("#sf").find(
      'input[type="text"], input[type="email"],input[type="file"] ,textarea'
    );
    textFields.each(function () {
      $(this).css("border", ""); // Clear border color
    });
  });

  const Closebtn = () => {
    debugger;
    $("#sf")[0].reset();
    const textFields = $("#sf").find(
      'input[type="text"], input[type="email"],input[type="file"] ,textarea'
    );
    textFields.each(function () {
      $(this).css("border", ""); // Clear border color
    });
    $("#oppourtunitiesModelClsBtn").click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setApplyFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function firstNameChange() {
    var FirstName = $("#firstName").val();
    if (FirstName == "") {
      document.getElementById("firstName").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("firstName").style.border = "";
    }
  }
  function lastNameChange() {
    var FirstName = $("#lastName").val();
    if (FirstName == "") {
      document.getElementById("lastName").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("lastName").style.border = "";
    }
  }
  // function CheckEmailChange() {
  //   var email = $("#email").val().trim();
  //   var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i;

  //   if (email === "") {
  //     $("#emailError").text("Email is Reqiured");
  //   } else if (!emailRegex.test(email)) {
  //     $("#emailError").text("Invalid Email Address");
  //   } else {
  //     $("#emailError").text("");
  //   }
  // }

  function CheckEmailChange() {
    var email = $("#email").val();
    if (email === "") {
      document.getElementById("email").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("email").style.border = "";
    }
  }
  function CantactNumberChange(e) {
    const inputValue = e.target.value;
    // Replace all characters that are not digits, plus sign, or parentheses
    const sanitizedValue = inputValue.replace(/[^\d+()]/g, "");
    // Update the input value with the sanitized value
    e.target.value = sanitizedValue;
  }
  function ContactNumber() {
    var FirstName = $("#contactNumber").val();
    if (FirstName == "") {
      document.getElementById("contactNumber").style.border =
        "1px solid #dd3535";
    } else {
      document.getElementById("contactNumber").style.border = "";
    }
  }
  function cityChange() {
    var cityName = $("#city").val();
    if (cityName == "") {
      document.getElementById("city").style.border = "1px solid #dd3535";
    } else {
      document.getElementById("city").style.border = "";
    }
  }
  const ResumeChange = (e) => {
    debugger;
    const checkPDF = e.target.files[0];

    if (checkPDF) {
      const fileName = checkPDF.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension !== "pdf") {
        Swal.fire({
          icon: "warning",
          title: "Please upload a PDF formated file.",
          position: "top-end",
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          customClass: {
            container: "custom-swal-container",
          },
        });
        //  document.getElementById('resumePath').innerText = 'Please upload a PDF formated file.';
        e.target.value = "";
      } else {
        // element.style.border = '';

        document.getElementById("resumePath").style.border = "";
      }
    }
  };
  const handleEligibleChange = (e) => {
    debugger;
    const { id } = e.target;
    let value = 0;

    if (id === "Yes") {
      value = 1;

      document.getElementById("No").checked = false;
    } else {
      document.getElementById("Yes").checked = false;
    }

    setApplyFormDetails((prevState) => ({
      ...prevState,
      eligibleToUS: value,
    }));
  };
  const handlesponsershipToWorkUSChange = (e) => {
    debugger;
    const { id } = e.target;
    let value = 0;

    if (id === "1") {
      value = 1;

      document.getElementById("0").checked = false;
    } else {
      document.getElementById("1").checked = false;
    }

    setApplyFormDetails((prevState) => ({
      ...prevState,
      sponsershipToWorkUS: value,
    }));
  };

  return (
    <>
      <section className="contact-area">
        <div className="row  fadeInUp">
          <form
            onSubmit={onSubmit}
            className="contact-form"
            id="sf"
            autoComplete="off"
          >
            <div className="col-md-12">
              <div className="contact-form">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      onBlur={firstNameChange}
                      onChange={handleChange}
                    />
                    <span className="text-danger"></span>
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      onChange={handleChange}
                      onBlur={lastNameChange}
                      //   value={formData.name}
                    />
                    <span className="text-danger"></span>
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      id="email"
                      onChange={handleChange}
                      onBlur={CheckEmailChange}
                      //   value={formData.email}
                    />
                    <span className="text-danger"> </span>
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Phone Number
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="contactNumber"
                      className="form-control"
                      id="contactNumber"
                      maxLength={13}
                      // onKeyDown={onKeyDown}
                      //   value={formData.phone}
                      onBlur={ContactNumber}
                      onChange={handleChange}
                      onInput={CantactNumberChange}
                    />
                    <span className="text-danger"></span>
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Location(city)<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      id="city"
                      onChange={handleChange}
                      onBlur={cityChange} // If you want to check on blur
                      //   value={formData.location}
                    />
                    <span className="text-danger"> </span>
                  </div>

                  <div className="form-group col-md-6">
                    <label>
                      Resume<small className="text-muted">(PDF only)</small>
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="file"
                      name="resumePath"
                      accept=".pdf"
                      className="form-control"
                      id="resumePath"
                      onChange={ResumeChange}
                      //   value={formData.companyName}
                      style={{ paddingTop: "10px", paddingLeft: "10px" }}
                    />
                    <span className="text-danger"></span>
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Are you Eligible to work in the United States ?{" "}
                    </label>
                    <div className="form-check radiooption">
                      <div>
                        <label htmlFor="Yes" className="radio-inline">
                          <input
                            type="radio"
                            id="Yes"
                            name="eligibleForH1BMaster"
                            onChange={handleEligibleChange}
                          />
                          Yes
                        </label>
                        <label htmlFor="No" className="radio-inline">
                          <input
                            type="radio"
                            id="No"
                            name="eligibleForH1BMaster"
                            onChange={handleEligibleChange}
                            // disabled={flag}
                            // checked={AppliersDetails.eligibleForH1BMaster == '0' ? true : false}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label>
                      Will you now, or in the future, require sponsorship to
                      work in the United States?{" "}
                    </label>
                    <div className="form-check radiooption">
                      <div>
                        <label htmlFor="Yes" className="radio-inline">
                          <input
                            type="radio"
                            id="1"
                            name="sponsershipToWorkUS"
                            onChange={handlesponsershipToWorkUSChange}

                            // checked={AppliersDetails.eligibleForH1BMaster == '1' ? true : false}
                          />
                          Yes
                        </label>
                        <label htmlFor="No" className="radio-inline">
                          <input
                            type="radio"
                            id="0"
                            name="sponsershipToWorkUS"
                            onChange={handlesponsershipToWorkUSChange}
                            // disabled={flag}
                            // checked={AppliersDetails.eligibleForH1BMaster == '0' ? true : false}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="button" onClick={Closebtn}>
                      Cancel
                    </button>
                    {!isLoaded ? (
                      <button
                        style={{ marginLeft: "10px" }}
                        type="submit"
                        name="submit"
                        id="submit"
                      >
                        Apply
                      </button>
                    ) : (
                      <button
                        type="button"
                        style={{ marginLeft: "10px" }}
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    )}

                    {/* <button type="submit" name="submit" id="submit">Send Message</button> */}
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* /END  CONTACT DETAILS DESIGN AREA */}
        </div>
      </section>
    </>
  );
};
export default ApplyOppourtunities;
