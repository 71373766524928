import React, { useEffect, useState } from "react";

const ExcellanceBody= ()=>{
    return(
    <>
     <section>
    <div className="why-chhose-us-area section-padding">
      <div className="container section-padding">
        <div class="row">
                  <div class="col-sm-12">
                    <div className="single-about">
                    <div class="section-title">
                      <h2>EVERY WORK IS A MASTERPIECE</h2>
                      </div>
                    </div>
                     
                  </div>
              </div>
        <div className="row  ">
          <div className="col-md-12">
            <div className="why-image">
              <img className="ExcellanceImgresize"src="/assets/images/Excellence.jpg" alt="" />
            </div>
          </div>
          <section id="team" style={{ marginTop: '400px' }}>
          <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="section-title">
            <h2>OUR CLIENTS</h2>
          </div>
        </div>
      </div>
      <div className="row">
        {/* START SINGLE TEAM DESIGN AREA */}
        <div className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay=".2s">
          <div className="single-team text-center">
            <img src="/assets/images/client1.png" alt="" />
            <div className="team-description text-center">
              <h4>City of Aspen </h4>
            </div>
          </div>
        </div>
        {/* / END SINGLE TEAM DESIGN AREA */}
        {/* START SINGLE TEAM DESIGN AREA */}
        <div className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay=".4s">
          <div className="single-team text-center">
            <img src="/assets/images/Client2.png" alt="" />
            <div className="team-description text-center">
              <h4>City of Jackson, MS </h4>
            </div>
          </div>
        </div>
        {/* / END SINGLE TEAM DESIGN AREA */}
        {/* START SINGLE TEAM DESIGN AREA */}
        <div className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay=".6s">
          <div className="single-team text-center">
            <img src="/assets/images/client3.png" alt="" />
            <div className="team-description text-center">
              <h4>City of Byram, MS </h4>
            </div>
          </div>
        </div>
        {/* / END SINGLE TEAM DESIGN AREA */}
      </div>
    </div>
  </section>
          <div className="col-md-12" style={{ marginTop: '50px' }}>
          <div className="single-about">
                    <div class="section-title">
                      <h2>OUR RECENT PROJECTS</h2>
                      </div>
                    </div>
            <div className="row">
             
             
             
              <div
                className="col-md-12  wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="single-about">
                  <div className="about-icon-Excellence">
                    <span className="lnr lnr-pie-chart" />
                  </div>
                  <p className="justify">
                    Implemented Oracle Customer Care and Billing System and
                    Interfaces to General Ledger{" "}
                  </p>
                </div>
              </div>
              {/* / END SINGLE WHY CHOOSE DESIGN AREA */}
              {/* START SINGLE WHY CHOOSE DESIGN AREA */}
              <div
                className="col-md-12  wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="single-about">
                  <div className="about-icon-Excellence">
                    <span className="lnr lnr-crop" />
                  </div>
                  <p className="justify">
                    Oracle Time and Labor Implementation . Migrate current
                    Kronos system to Oracle Time and Labor &amp; Payroll
                  </p>
                </div>
              </div>
              {/* / END SINGLE WHY CHOOSE DESIGN AREA */}
              {/* START SINGLE WHY CHOOSE DESIGN AREA */}
              <div
                className="col-md-12 wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="single-about">
                  <div className="about-icon-Excellence">
                    <span className="lnr lnr-text-align-justify" />
                  </div>
                  <p className="justify">
                    Integrate legacy utility meters with Oracle Customer Care
                    and Billing{" "}
                  </p>
                </div>
              </div>
              {/* / END SINGLE WHY CHOOSE DESIGN AREA */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
    </>)
}
export default ExcellanceBody