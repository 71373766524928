import React, { useEffect, useState } from "react";
import ApplyOppourtunities from "./ApplyOppourtunities";
import JobDetailsModal from "./JobDetailsModal";
import JavaJobDetails from "./JavaJobDetails";

const OppourtunitiesBody = () => {
  const [text, setText] = useState("");
  return (
    <>
      <section id="team" className="team-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="single-about ">
                <div className="section-title">
                  <h2>Join Us and Shape the Future of IT</h2>
                </div>
                <p>Are you ready to be a game-changer in the IT world?</p>
              </div>
              <p className="text-black">
                At PEN Software,we don’t just adapt to change; we create it. Our
                mission is to revolutionize businesses through our cutting-edge
                expertise in Digital Transformation, Oracle and SAP Consulting,
                Professional Staffing, and Custom IT Solutions.
              </p>
              <p className="text-black">
                We're all about people and relationships. If you're passionate
                and want to make a real impact in a dynamic, inclusive
                environment, join us.
              </p>
              <p className="text-black">Ready to Make a Difference?</p>
              <p className="text-black">
                E-mail us at{" "}
                <a href="mailto:hr@pensoftware.io" className="email-link">
                  hr@pensoftware.io
                </a>{" "}
                and let’s start the conversation!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="team" className="">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-blog-title">
                <h2>Current Openings</h2>
                <div className="job-listing">
                  <div className="row">
                    <div className="col-sm-10">
                      <div className="job-title">
                        Oracle Financials Techno-Functional Consultant
                      </div>
                      <div className="job-location">San Jose, CA, USA</div>
                      <div className="job-type">Contract</div>
                      <button
                        className="read-more "
                        style={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: "#333",
                          padding: "10px 25px",
                          borderRadius: "100px",
                        }}
                        href="#"
                        data-toggle="modal"
                        data-target="#jobDetailsModal"
                      >
                        {" "}
                        Read More
                      </button>
                    </div>
                    <div className="col-sm-2">
                      <a
                        className="apply-now"
                        href=""
                        data-toggle="modal"
                        data-target="#addRowModal"
                        onClick={() =>
                          setText(
                            "Oracle Financials Techno-Functional Consultant"
                          )
                        }
                        // onClick={() => setModel({ ...v })}
                      >
                        {" "}
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
                <div className="job-listing">
                  <div className="row">
                    <div className="col-sm-10">
                      <div className="job-title">Senior Java Developer</div>
                      <div className="job-location">Dallas, Texas, USA</div>
                      <div className="job-type">Contract</div>
                      <button
                        className=" read-more"
                        href="#"
                        data-toggle="modal"
                        data-target="#javaJobDetailsModal"
                      >
                        Read More
                      </button>
                      {/* <p>
                        <b>
                          Position: Oracle Financials Techno-Functional
                          Consultant
                        </b>
                      </p>
                      <p>
                        <b>Work Location:</b>San Jose, CA , USA
                      </p>
                      <p>
                        <b>Work Place Type:</b> Onsite
                      </p>
                      <p>
                        <b>Employment Type:</b> Contract
                      </p>
                      <p>
                        <b>Contract Term: </b> 12 months (+ Further Extension
                        possible)
                      </p>
                      <p>
                        <b>Summary :</b>
                        <span>
                          As an Oracle Financials Techno-Functional Consultant,
                          you will be responsible for Oracle Financials EBS
                          projects for PEN Software’s clients on a contract
                          basis.
                        </span>
                      </p>
                      <p>
                        <b>Indicative Experience: </b>10 years + in Oracle EBS
                      </p>
                      <p>
                        <b>Required Skills for the position:</b>
                      </p>
                      <p className="Oppo-skills">
                        • Techno Functional experience supporting Procure to
                        Pay, Cash Management (Bank Reconciliations, Bank
                        Integrations knowledge is a plus), Project Billing ,
                        Service Contracts.
                      </p>
                      <p className="Oppo-skills">
                        • Hands On Technical knowledge on Oracle Forms, Reports,
                        Workflow, Interfaces, Integrations and added advantage
                        if have knowledge on Tableau.
                      </p>
                      <p className="Oppo-skills">
                        • Good Communication and interpersonal skills to engage
                        with the cross functional team members for project and
                        support assignments.
                      </p>
                      <p className="Oppo-skills">
                        • Should be able to communicate with the business for
                        driving business requirements, Demo, User Acceptance
                        testing, Support, etc.
                      </p> */}
                    </div>
                    <div className="col-sm-2">
                      <a
                        className="apply-now "
                        href=""
                        data-toggle="modal"
                        data-target="#addRowModal"
                        // onClick={() => setModel({ ...v })}
                        onClick={() => setText("Senior Java Developer")}
                      >
                        {" "}
                        Apply Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="jobDetailsModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-dialog modal-lg"
          style={{ marginTop: "116px" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Job Description</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <JobDetailsModal />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="javaJobDetailsModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-dialog modal-lg"
          style={{ marginTop: "116px" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Job Description</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <JavaJobDetails />
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="addRowModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          class="modal-dialog modal-lg"
          style={{ marginTop: "85px" }}
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Apply Opportunities</h5>
              <button
                type="button"
                id="oppourtunitiesModelClsBtn"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <ApplyOppourtunities text={text} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OppourtunitiesBody;
